<template>
  <button :class="`n-${styleName}`" class="n-primary tw-cursor-pointer" @click="didClick()" :disabled="isDisabled">
    <label class="button-label">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
  </button>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'LibraryButton',

  // Still WIP

  props: {
    label: {
      required: false,
      type: String,
    },

    isDisabled: {
      required: false,
      type: Boolean,
    },

    height: {
      required: false,
      type: Number,
      default: 60,
    },

    width: {
      required: false,
      type: Number,
      default: null,
    },

    styleName: {
      required: false,
      default: 'primary',
      type: String,
    },

    isLink: {
      required: false,
      type: Boolean,
      default: false,
    },

    linkTarget: {
      required: false,
      type: String,
      default: null,
    },
  },

  emits: ['didClick'],

  methods: {
    didClick() {
      this.$emit('didClick');
    },
  },
});
</script>
