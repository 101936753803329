export class EmailValidator {
  private regex =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  private checkEmailExistence(email: string) {
    if (!email || !email.trim()) {
      throw new Error('Please enter email address');
    }
  }

  private checkEmailInValidFormat(email: string) {
    const emailParts = email.split('@');

    if (emailParts.length !== 2) return false;

    const account = emailParts[0];
    const address = emailParts[1];

    if (account.length > 64) return false;
    else if (address.length > 255) return false;

    const domainParts = address.split('.');

    if (
      domainParts.some(function (part) {
        return part.length > 63;
      })
    )
      return false;

    return this.regex.test(email);
  }

  public validate(email: string) {
    this.checkEmailExistence(email);

    const result = this.checkEmailInValidFormat(email);
    if (!result) {
      throw new Error('Please enter valid email address');
    }
    return email.trim();
  }
}
